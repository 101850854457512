import instance from "@/services/instance.js";

const VatRate = payload => {
  return instance.instance
    .get(`/v2/payment/vatrate/${payload}`)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const VatNum = payload => {
  return instance.instance
    .get(`/v2/payment/vatnum/${payload}`)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const DirectPaymentAdmin = payload => {
  return instance.instance
    .post(`/v2/payment/directPaymentAdmin`, payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

export default {
  vatRate: VatRate,
  vatNum: VatNum,
  directPaymentAdmin: DirectPaymentAdmin
};
