/* eslint-disable */
import instance from "@/services/instance.js";

const GetAllCitySub = params => {
  return instance.instanceToken
    .get("/citySubscriber/all", {params})
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const SetApproval = payload => {
  return instance.instanceToken
    .post("/citySubscriber/approval", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const SetAdvance = payload => {
  return instance.instanceToken
    .post("/citySubscriber/advance", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const SetPaymentApproval = payload => {
  return instance.instanceToken
    .put("/citySubscriber/paymentApproval", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const SetDelete = payload => {
  return instance.instanceToken
    .post("/citySubscriber/delete", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const GetAllCityName = payload => {
  return instance.instanceToken
    .get("/city/allList", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};
const UpdateAccount = payload => {
  return instance.instanceToken
    .post("/citySubscriber/admin_update", payload)
    .then(function (response) {
      return response;
    })
    .catch(function () {
    });
};
const DeleteSustainabilityCertificate= (payload) => {
  return instance.instanceToken
    .delete("/supportDocuments/delete_sustainability_certificate", {
      data: payload
    })
};
export default {
  setApproval: SetApproval,
  getAllCitySub: GetAllCitySub,
  getAllCityName: GetAllCityName,
  setAdvance: SetAdvance,
  setDelete: SetDelete,
  setPaymentApproval: SetPaymentApproval,
  updateAccount:UpdateAccount,
  deleteSustainabilityCertificate:DeleteSustainabilityCertificate
};
