<template>
  <v-autocomplete
    v-model="country"
    :items="countries"
    item-text="name"
    outlined
    required
    label="Country"
    :error-messages="errorMessages"
  ></v-autocomplete>
</template>

<script>
import Countries from "../../util/Countries";

export default {
  name: "CountryAutocomplete",
  props: ["value", "errorMessages"],
  data() {
    return {
      countries: [],
      country: null
    };
  },
  watch: {
    country(val) {
      this.$emit(
        "input",
        this.countries.find(country => country.name === val)
      );
    },
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.country = val.name;
        }
      }
    }
  },
  mounted() {
    this.countries = Countries;
  }
};
</script>