<template>
  <v-dialog v-model="approvalDialog" :key="item._id" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon :color="item.isApproved ? 'success' : 'secondary'">
        <font-awesome-icon
          :icon="['fas', item.isApproved ? 'check-circle' : 'ban']"
          :color="item.isApproved ? 'success' : 'secondary'"
        />
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h3 class="title text-start text-break">
          Are you sure you want to
          {{ item.isApproved ? "revoke the approval on" : "approve" }}
          <span class="text-capitalize">'{{ item.cityName || item.countryName }}'</span>?
        </h3>
      </v-card-title>
      <v-card-text>
        <p class="subtitle-2 text-left">
          <span class="text-capitalize">'{{ item.cityName || item.countryName}}'</span>
          will become {{ item.isApproved ? "invisible" : "visible" }} on the
          Conferli platform.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="approvalDialog = false"
          >No, Cancel</v-btn
        >
        <v-btn
          color="error"
          text
          v-if="item.isApproved"
          @click="
            approvalFn(item);
            approvalDialog = false;
          "
          >Yes, Revoke</v-btn
        >
        <v-btn
          color="success"
          text
          v-else
          @click="
            approvalFn(item);
            approvalDialog = false;
          "
          >Yes, Approve</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ApprovalConfirmation",
  props: {
    item: Object,
    approvalFn: Function
  },
  data() {
    return {
      approvalDialog: false
    };
  },
  computed: {},
  watch: {}
};
</script>
