<template>
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <!-- <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">City Subscribers</div>
            <div class="subtitle-1 font-weight-light">
              A list of City Subscribers
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="end"
            >
              <v-col cols="12" md="8" lg="5">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.endDate`]="{ item }">{{
                    item.endDate.split("T")[0]
                  }}</template>
                  <template v-slot:[`item.isAdvance`]="{ item }">
                    <font-awesome-icon
                      :icon="['fas', item.isAdvance ? 'check-circle' : 'ban']"
                      :class="item.isAdvance ? 'success--text' : 'secondary--text'"
                      size="lg"
                    />
                  </template>
                  <template v-slot:[`item.payment`]="{ item }">
                    <MakePaymentModal :item="item"
                     :key="item._id"
                     />
                  </template>
                  <template v-slot:[`item.approval`]="{ item }">
                    <ApprovalConfirmation
                      :item="item"
                      :key="`itemId-approval ${item._id}`"
                      :approvalFn="approvalFn"
                    />
                  </template>
                  <template v-slot:[`item.upgrade`]="{ item }">
                    <v-btn
                      :color="getCityApprovalColor(item.isPaymentApproved)"
                      :disabled="item.isPaymentApproved == 0"
                      @click="paymentApprovalFn(item)"
                      class="upgrade-btn"
                    >
                      <span>{{
                        getCityApprovalState(item.isPaymentApproved)
                      }}</span>
                    </v-btn>
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="payloadGen(item)"
                      itemtype="City Subscriber"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item._id, item.email)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card> -->
        <!-- <v-dialog v-model="isDialog" scrollable max-width="600px">
          <v-card>
            <v-card-title class="px-2 px-sm-6">
              <v-row class="pb-3">
                <v-col
                  cols="8"
                  lg="10"
                  class="d-flex flex-column justify-center align-start py-0"
                >
                  <h2 class="headline text-left">Price Approval</h2>
                </v-col>
                <v-col
                  cols="4"
                  lg="2"
                  class="d-flex flex-column justify-center align-end py-0"
                >
                  <v-btn icon color="grey lighten-1" @click="closeDialog">
                    <v-icon class="dialog-close-icon">mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container class="px-0">
                <v-row class="mx-0">
                  <v-col cols="12" class="px-0"> -->
        <!-- individual row for event summary details -->
        <!-- <v-row class="px-0 mb-3">
                      <v-col cols="8">
                        <h2 class="text-left title">Chosen Plan:</h2>
                      </v-col>
                      <v-col cols="4">
                        <h2 class="text-left title primary--text">
                          {{
                            isPricePlanAnnual ? "Advance Plan" : "Advance Plan"
                          }}
                        </h2>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="px-0"> -->
        <!-- individual row for event summary details -->
        <!-- <v-row class="px-0">
                      <v-col cols="12" class="mb-1">
                        <h2 class="text-left title">Pricing Breakdown:</h2>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Annual Price:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          €{{ priceAnnually }}
                        </h5>
                      </v-col> -->
        <!-- <v-col cols="8">
                        <h4 class="text-left subtitle-1">Monthly Price:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">€{{priceMonthly}}</h5>
                      </v-col>-->
        <!-- </v-row>
                  </v-col>
                  <v-col cols="12" class="px-0"> -->
        <!-- individual row for event summary details -->
        <!-- <v-row class="px-0" v-if="paymentSchema">
                      <v-col cols="12" class="mb-1">
                        <h2 class="text-left title">Calculation Factors:</h2>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Capital City:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.isCapital ? "Yes" : "No" }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Convention Bureau:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.isConvention ? "Yes" : "No" }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Int. Train Stop:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.isTrain ? "Yes" : "No" }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">
                          Distance to Airport:
                        </h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.airport.str }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Population:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.population.str }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">
                          Max. Number of Delegates:
                        </h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.delegates.str }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">
                          Association meetings (Prev. Year):
                        </h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.meetings.str }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Team Members:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.team.str }}
                        </h5>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row> -->
        <!-- <v-row>isAnuval : {{isPricePlanAnnual}}</v-row>
                <v-row>Annually :{{priceAnnually}}</v-row>
                <v-row>Monthly :{{priceMonthly}}</v-row>
                <v-row>{{paymentSchema}}</v-row>-->
        <!-- <v-row>
                  <v-col cols="12" class="mb-1">
                    <h2 class="text-left title">Discount:</h2>
                  </v-col> -->
        <!-- <v-col cols="12" sm="6" class="py-0">
                    <v-select
                      label="Type"
                      v-model="discountType"
                      :items="discountList"
                      return-object
                      item-text="str"
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="discountType && discountType.val == 2"
                    cols="12"
                    sm="6"
                    class="py-0"
                  >
                    <v-text-field
                      label="Percentage"
                      v-model="discountValue"
                      outlined
                      suffix="%"
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" sm="6" class="py-0">
                    <v-text-field
                      label="Amount"
                      v-model="discountValue"
                      outlined
                      suffix="€"
                    ></v-text-field>
                  </v-col> -->
        <!-- </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-row
                      class="d-flex flex-row align-center justify-space-between mx-0"
                    >
                      <h5 class="text-left subtitle-1">
                        Is there any discounts?
                      </h5>
                      <v-switch
                        v-model="isDiscount"
                        :disabled="adminType != 'master'"
                      ></v-switch>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-select
                      class="my-0"
                      :items="discountList"
                      v-model="discountType"
                      label="Discount Type"
                      :error-messages="errorDiscountType.message"
                      outlined
                      :disabled="!isDiscount"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="discountType == 'Amount'"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <v-text-field
                      class="my-0"
                      v-model="discountValue"
                      label="Discount Value"
                      suffix="€"
                      @keypress="[isNumber()]"
                      :error-messages="errorDiscountValue.message"
                      outlined
                      :disabled="!isDiscount"
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" md="6" class="py-0">
                    <v-text-field
                      class="my-0"
                      v-model="discountValue"
                      label="Discount Value"
                      suffix="%"
                      @keypress="[isNumber()]"
                      :error-messages="errorDiscountValue.message"
                      outlined
                      :disabled="!isDiscount"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-1">
                    <h2 class="text-left title">Approval State:</h2>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-select
                      label="State"
                      v-model="state"
                      :items="stateList"
                      return-object
                      item-text="str"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="px-6">
              <v-row
                class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0"
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-start px-0"
                >
                  <v-btn
                    class="px-8"
                    color="secondary"
                    @click="closeDialog"
                    tile
                    outlined
                    depressed
                    bottom
                    >Cancel</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-end px-0"
                >
                  <v-btn
                    class="px-8"
                    @click="paymentApprovalSubmitFn"
                    color="secondary"
                    tile
                    depressed
                    bottom
                    right
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="CitySubTabs"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="display-2 font-weight-light mx-3"
                style="align-self: center"
                >City Subscribers:</span
              >
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'user']" class="mr-2" />
                Active Subscribers
              </v-tab>
              <v-tab class="mr-3">
                <font-awesome-icon
                  :icon="['fas', 'user-alt-slash']"
                  class="mr-2"
                />
                Inactive Subscribers
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="CitySubTabs" class="transparent">
            <!-- Active subscribers -->
            <v-tab-item>
              <v-card-text>
                <v-row
                  class="mx-0 mb-0"
                  align="center"
                  justify="center"
                  justify-md="start"
                >
                  <v-col cols="12" md="8" lg="5">
                    <v-text-field
                      v-model="activeSearch"
                      append-icon="mdi-magnify"
                      label="Search by city or city rep"
                      single-line
                      outlined
                      hide-details
                      @keyup.native="searchFn"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" lg="1">
                    <v-btn
                      color="primary"
                      @click="clearFilterFn"
                      :variant="activeSearch ? 'ash-gray' : 'primary'"
                      :disabled="!activeSearch"
                    >
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="activeHeaders"
                  :items="activeItems"
                  :loading="isLoading"
                  :items-per-page="15"
                  @update:items-per-page="getActiveItemPerPage"
                  :options.sync="activePagination"
                  :footer-props="footerProps"
                  :server-items-length="totalOutputsActive"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <!-- <span class="subtitle-1 text-left" v-on="on"> -->
                    {{ DateFormatYYMMDD(item.created_at) }}
                    <!-- </span> -->
                  </template>
                  <template v-slot:[`item.endDate`]="{ item }"
                    >{{ DateFormatYYMMDD(item.endDate) }}
                  </template>
                  <template v-slot:[`item.isAdvance`]="{ item }">
                    <font-awesome-icon
                      :icon="['fas', item.isAdvance ? 'check-circle' : 'ban']"
                      :class="
                        item.isAdvance ? 'success--text' : 'secondary--text'
                      "
                      size="lg"
                    />
                  </template>
                  <template v-slot:[`item.payment`]="{ item }">
                    <MakePaymentModal :item="item" :key="item._id" />
                  </template>
                  <template v-slot:[`item.approval`]="{ item }">
                    <ApprovalConfirmation
                      :item="item"
                      :key="`itemId-approval ${item._id}`"
                      :approvalFn="approvalFn"
                    />
                  </template>
                  <template v-slot:[`item.upgrade`]="{ item }">
                    <!-- {{item.isPaymentApproved}} -->
                    <v-btn
                      :color="getCityApprovalColor(item.isPaymentApproved)"
                      :disabled="item.isPaymentApproved == 0"
                      @click="paymentApprovalFn(item)"
                      class="upgrade-btn"
                    >
                      <span>{{
                        getCityApprovalState(item.isPaymentApproved)
                      }}</span>
                    </v-btn>
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="payloadGen(item)"
                      itemtype="City Subscriber"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item._id, item.email)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <!-- Inactive subscribers -->
            <v-tab-item>
              <v-card-text>
                <v-row
                  class="mx-0 mb-0"
                  align="center"
                  justify="center"
                  justify-md="start"
                >
                  <v-col cols="12" md="8" lg="5">
                    <v-text-field
                      v-model="inactiveSearch"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      outlined
                      hide-details
                      @keyup.native="searchFn"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" lg="1">
                    <v-btn
                      color="primary"
                      @click="clearFilterFn"
                      :variant="inactiveSearch ? 'ash-gray' : 'primary'"
                      :disabled="!inactiveSearch"
                    >
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="inactiveHeaders"
                  :items="inactiveItems"
                  :loading="isLoading"
                  :options.sync="inactivePagination"
                  :items-per-page="15"
                  :footer-props="footerProps"
                  @update:items-per-page="getInctiveItemPerPage"
                  :server-items-length="totalOutputsInactive"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <!-- <span class="subtitle-1 text-left" v-on="on"> -->
                    {{ DateFormatYYMMDD(item.created_at) }}
                    <!-- </span> -->
                  </template>
                  <template v-slot:[`item.endDate`]="{ item }">
                    {{ DateFormatYYMMDD(item.endDate) }}
                  </template>
                  <template v-slot:[`item.isAdvance`]="{ item }">
                    <font-awesome-icon
                      :icon="['fas', item.isAdvance ? 'check-circle' : 'ban']"
                      :class="
                        item.isAdvance ? 'success--text' : 'secondary--text'
                      "
                      size="lg"
                    />
                  </template>
                  <template v-slot:[`item.payment`]="{ item }">
                    <MakePaymentModal
                      :item="item"
                      :key="item._id"
                      @reload="reloadData()"
                    />
                  </template>
                  <template v-slot:[`item.approval`]="{ item }">
                    <ApprovalConfirmation
                      :item="item"
                      :key="`itemId-approval ${item._id}`"
                      :approvalFn="approvalFn"
                    />
                  </template>
                  <template v-slot:[`item.upgrade`]="{ item }">
                    <v-btn
                      :color="getCityApprovalColor(item.isPaymentApproved)"
                      :disabled="item.isPaymentApproved == 0"
                      @click="paymentApprovalFn(item)"
                      class="upgrade-btn"
                    >
                      <span>{{
                        getCityApprovalState(item.isPaymentApproved)
                      }}</span>
                    </v-btn>
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="payloadGen(item)"
                      itemtype="City Subscriber"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item._id, item.email)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
        <v-dialog v-model="isDialog" scrollable max-width="600px">
          <v-card>
            <v-card-title class="px-2 px-sm-6">
              <v-row class="pb-3">
                <v-col
                  cols="8"
                  lg="10"
                  class="d-flex flex-column justify-center align-start py-0"
                >
                  <h2 class="headline text-left">Price Approval</h2>
                </v-col>
                <v-col
                  cols="4"
                  lg="2"
                  class="d-flex flex-column justify-center align-end py-0"
                >
                  <v-btn icon color="grey lighten-1" @click="closeDialog">
                    <v-icon class="dialog-close-icon">mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container class="px-0">
                <v-row class="mx-0">
                  <v-col cols="12" class="px-0">
                    <!-- individual row for event summary details -->
                    <v-row class="px-0 mb-3">
                      <v-col cols="8">
                        <h2 class="text-left title">Chosen Plan:</h2>
                      </v-col>
                      <v-col cols="4">
                        <h2 class="text-left title primary--text">
                          {{
                            isPricePlanAnnual ? "Advance Plan" : "Advance Plan"
                          }}
                        </h2>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <!-- individual row for event summary details -->
                    <v-row class="px-0">
                      <v-col cols="12" class="mb-1">
                        <h2 class="text-left title">Pricing Breakdown:</h2>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Annual Price:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ priceAnnually ? "€" + priceAnnually : "N/A" }}
                        </h5>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="px-0">
                    <!-- individual row for event summary details -->
                    <v-row class="px-0" v-if="paymentSchema">
                      <v-col cols="12" class="mb-1">
                        <h2 class="text-left title">Calculation Factors:</h2>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Capital City:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.isCapital ? "Yes" : "No" }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Convention Bureau:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.isConvention ? "Yes" : "No" }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Int. Train Stop:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{ paymentSchema.isTrain ? "Yes" : "No" }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">
                          Distance to Airport:
                        </h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{
                            paymentSchema.airport && paymentSchema.airport.str
                              ? paymentSchema.airport.str
                              : "N/A"
                          }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Population:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{
                            paymentSchema.population &&
                            paymentSchema.population.str
                              ? paymentSchema.population.str
                              : "N/A"
                          }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">
                          Max. Number of Delegates:
                        </h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{
                            paymentSchema.delegates &&
                            paymentSchema.delegates.str
                              ? paymentSchema.delegates.str
                              : "N/A"
                          }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">
                          Association meetings (Prev. Year):
                        </h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{
                            paymentSchema.meetings && paymentSchema.meetings.str
                              ? paymentSchema.meetings.str
                              : "N/A"
                          }}
                        </h5>
                      </v-col>
                      <v-col cols="8">
                        <h4 class="text-left subtitle-1">Team Members:</h4>
                      </v-col>
                      <v-col cols="4">
                        <h5 class="text-left subtitle-2">
                          {{
                            paymentSchema.team && paymentSchema.team.str
                              ? paymentSchema.team.str
                              : "N/A"
                          }}
                        </h5>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-1">
                    <h2 class="text-left title">Discount:</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-row
                      class="d-flex flex-row align-center justify-space-between mx-0"
                    >
                      <h5 class="text-left subtitle-1">
                        Is there any discounts?
                      </h5>
                      <v-switch
                        v-model="isDiscount"
                        :disabled="adminType !== 'master'"
                      ></v-switch>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-select
                      class="my-0"
                      :items="discountList"
                      v-model="discountType"
                      label="Discount Type"
                      :error-messages="errorDiscountType.message"
                      outlined
                      :disabled="!isDiscount"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="discountType === 'Amount'"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <v-text-field
                      class="my-0"
                      v-model="discountValue"
                      label="Discount Value"
                      suffix="€"
                      @keypress="[isNumber()]"
                      :error-messages="errorDiscountValue.message"
                      outlined
                      :disabled="!isDiscount"
                    ></v-text-field>
                  </v-col>
                  <v-col v-else cols="12" md="6" class="py-0">
                    <v-text-field
                      class="my-0"
                      v-model="discountValue"
                      label="Discount Value"
                      suffix="%"
                      @keypress="[isNumber()]"
                      :error-messages="errorDiscountValue.message"
                      outlined
                      :disabled="!isDiscount"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="mb-1">
                    <h2 class="text-left title">Approval State:</h2>
                  </v-col>
                  <v-col cols="12" sm="6" class="py-0">
                    <v-select
                      label="State"
                      v-model="state"
                      :items="stateList"
                      return-object
                      item-text="str"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="px-6">
              <v-row
                class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0"
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-start px-0"
                >
                  <v-btn
                    class="px-8"
                    color="secondary"
                    @click="closeDialog"
                    tile
                    outlined
                    depressed
                    bottom
                    >Cancel
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-end px-0"
                >
                  <v-btn
                    class="px-8"
                    @click="paymentApprovalSubmitFn"
                    color="secondary"
                    tile
                    depressed
                    bottom
                    right
                    :loading="isLoading"
                    >Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import citySubscribersApicalls from "../../../services/citySubscribers.service";
import ApprovalConfirmation from "@/dashboard/Common/ApprovalConfirmation";
import MakePaymentModal from "@/dashboard/Common/MakePaymentModal";
import DeleteConfirmation from "@/dashboard/Common/DeleteConfirmation";
import isNumber from "../../../mixin/IsNumber";
import DateFormatYYMMDD from "../../../mixin/DateFormatYYMMDD";

export default {
  name: "CitySub",
  mixins: [isNumber],
  components: {
    ApprovalConfirmation,
    MakePaymentModal,
    DeleteConfirmation,
  },
  data() {
    return {
      activeSearch: null,
      inactiveSearch: null,
      isLoading: false,
      isDialog: false,
      paymentSchema: null,
      adminType: null,
      isPricePlanAnnual: null,
      priceAnnually: null,
      priceMonthly: null,
      citySubId: null,
      discountType: null,
      discountValue: null,
      isDiscount: false,
      errorDiscountType: {
        message: "",
      },
      errorDiscountValue: {
        message: "",
      },
      state: null,
      stateList: [
        // { str: "≤ 20 km", val: 0 },
        // { str: "21-50 km", val: 1 },
        { str: "Accept", val: 2 },
        { str: "Reject", val: 3 },
      ],
      discountList: ["Percentage", "Amount"],
      activeItems: [],
      inactiveItems: [],
      CitySubTabs: 0,
      totalOutputsActive: 0,
      totalOutputsInactive: 0,
      footerProps: { "items-per-page-options": [5, 15, 30] },
      activePagination: {
        rowsPerPage: 15,
        page: 1,
      },
      inactivePagination: {
        rowsPerPage: 15,
        page: 1,
      },
      inactiveHeaders: [
        {
          sortable: true,
          text: "City",
          value: "cityName",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "City Rep.",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Joined Date",
          value: "created_at",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Subs. End Date",
          value: "endDate",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Advance",
          value: "isAdvance",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Make Payment",
          value: "payment",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Approve User Payment",
          value: "upgrade",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Approve City",
          value: "approval",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Delete",
          value: "delete",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title",
        },
      ],
      // items: [
      //   {
      //     cityName: "Arnhem",
      //     name: "Nienke",
      //     email: "nvdmalen@gmail.com",
      //     endDate: "2022-01-12",
      //     payment: "null",
      //     isAdvance: false,
      //     upgrade: false,
      //   },
      // ],
    };
  },
  computed: {
    totalActive() {
      return this.totalOutputsActive;
    },
    activeHeaders() {
      return [
        {
          sortable: false,
          text: "City",
          value: "cityName",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "City Rep.",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Joined Date",
          value: "created_at",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Subs. End Date",
          value: "endDate",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Advance",
          value: "isAdvance",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Make Payment",
          value: "payment",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Approve User Payment",
          value: "upgrade",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Approve City",
          value: "approval",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Delete",
          value: "delete",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title",
        },
      ];
    },
    // headers() {
    //   let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;
    //   if (adminType == "master") {
    //     return [
    //       {
    //         sortable: true,
    //         text: "City",
    //         value: "cityName",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: true,
    //         text: "City Rep.",
    //         value: "name",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: true,
    //         text: "Email",
    //         value: "email",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: true,
    //         text: "Subs. End Date",
    //         value: "endDate",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: true,
    //         text: "Advance",
    //         value: "isAdvance",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: true,
    //         text: "Make Payment",
    //         value: "payment",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: true,
    //         text: "Approve User Payment",
    //         value: "upgrade",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: true,
    //         text: "Approve City",
    //         value: "approval",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: false,
    //         text: "Delete",
    //         value: "delete",
    //         class: "primary--text title",
    //       },
    //       {
    //         sortable: false,
    //         text: "More",
    //         value: "status",
    //         class: "primary--text title",
    //       },
    //     ];
    //   } else {
    //     return [
    //        {
    //   sortable: true,
    //   text: "City",
    //   value: "cityName",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "City Rep.",
    //   value: "name",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "Email",
    //   value: "email",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: true,
    //   text: "Joined Date",
    //   value: "created_at",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "Subs. End Date",
    //   value: "endDate",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "Advance",
    //   value: "isAdvance",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "Make Payment",
    //   value: "payment",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "Approve User Payment",
    //   value: "upgrade",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "Approve City",
    //   value: "approval",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "Delete",
    //   value: "delete",
    //   class: "primary--text title",
    // },
    // {
    //   sortable: false,
    //   text: "More",
    //   value: "status",
    //   class: "primary--text title",
    // },
    //     ];
    //   }
    // },
    isValid() {
      if (this.isDiscount) {
        if (this.discountType && this.discountValue) {
          if (this.discountType == "Percentage") {
            if (this.discountValue > 100) {
              return false;
            }
          }
          if (this.discountType == "Amount") {
            if (this.discountValue > this.priceAnnually) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  mounted() {
    // this.initFn();
    this.getActiveSubscribers();
  },
  watch: {
    discountType() {
      this.errorDiscountType.message = null;
    },
    discountValue() {
      this.errorDiscountValue.message = null;
    },
    isDiscount(val) {
      if (val == false) {
        this.discountType = null;
        this.errorDiscountType.message = null;
        this.discountValue = null;
        this.errorDiscountValue.message = null;
      }
    },
    activePagination() {
      this.getActiveSubscribers();
    },
    inactivePagination() {
      this.getInactiveSubscribers();
    },
  },
  methods: {
    reloadData() {
      this.getActiveSubscribers();
      this.getInactiveSubscribers();
    },
    DateFormatYYMMDD,
    getActiveItemPerPage(val) {
      this.activePagination.page = 1;
      this.activePagination.rowsPerPage = val;
    },
    getInctiveItemPerPage(val) {
      this.inactivePagination.page = 1;
      this.inactivePagination.rowsPerPage = val;
    },
    getActiveSubscribers() {
      this.isLoading = true;
      // const {page, rowsPerPage} = this.activePagination;
      const { page } = this.activePagination;
      citySubscribersApicalls
        .getAllCitySub({
          active: 1,
          limit: this.activePagination.rowsPerPage
            ? this.activePagination.rowsPerPage
            : 15, // add rows per page if backend pagination needed, but at the time I write this comment table will not support for server side pagination and client side sorting,
          page: page,
          name: this.activeSearch,
        })
        .then((res) => {
          this.totalOutputsActive = res.data.data.total;
          this.activeItems = res.data.data.results.map((x) => {
            return {
              cityName: x.cityName,
              name: x.name,
              email: x.email,
              endDate: x.endDate.split("T")[0],
              isAdvance: x.isAdvance,
              payment: x.payment,
              upgrade: false,
              approval: x.approval,
              ...x,
            };
          });
          this.isLoading = false;
        });
    },
    getInactiveSubscribers() {
      this.isLoading = true;
      // const {page, rowsPerPage} = this.inactivePagination;
      const { page } = this.inactivePagination;

      citySubscribersApicalls
        .getAllCitySub({
          active: 0,
          limit: this.inactivePagination.rowsPerPage
            ? this.inactivePagination.rowsPerPage
            : 15, // add rows per page if backend pagination needed, but at the time I write this comment table will not support for server side pagination and client side sorting,
          page: page,
          name: this.inactiveSearch,
        })
        .then((res) => {
          this.totalOutputsInactive = res.data.data.total;

          this.inactiveItems = res.data.data.results.map((x) => {
            return {
              cityName: x.cityName,
              name: x.name,
              email: x.email,
              endDate: x.endDate,
              isAdvance: x.isAdvance,
              payment: x.payment,
              upgrade: false,
              approval: x.approval,
              ...x,
            };
          });
          this.isLoading = false;
        });
    },
    closeDialog() {
      this.isDialog = false;
      this.paymentSchema = null;
      this.isPricePlanAnnual = null;
      this.priceAnnually = null;
      this.priceMonthly = null;
      this.citySubId = null;
    },
    paymentApprovalFn(item) {
      this.paymentSchema = item.paymentSchema;
      this.isPricePlanAnnual = item.isPricePlanAnnual;
      this.priceAnnually = item.priceAnnually;
      this.priceMonthly = item.priceMonthly;
      this.citySubId = item._id;
      this.isDialog = true;
    },
    async paymentApprovalSubmitFn() {
      if (this.isValid && this.state.val) {
        this.isLoading = true;
        let payload = {
          _id: this.citySubId,
          state: this.state.val,
          discountType:
            this.discountType === "Amount"
              ? "amount"
              : this.discountType === "Percentage"
              ? "percentage"
              : null,
          discountValue: this.discountValue,
          isDiscount: this.isDiscount,
        };
        await citySubscribersApicalls
          .setPaymentApproval(payload)
          .then(async (res) => {
            if (res.data.status === "success") {
              await citySubscribersApicalls.getAllCitySub().then((res) => {
                try {
                  this.activeItems = res.data.content.data;
                } catch (err) {
                  console.log("expected data not showed up");
                }
                this.isDialog = false;
                this.paymentSchema = null;
                this.isPricePlanAnnual = null;
                this.priceAnnually = null;
                this.priceMonthly = null;
                this.citySubId = null;
              });
            }
            if (res.data.status === "success") {
              await citySubscribersApicalls.getAllCitySub().then((res) => {
                try {
                  this.inactiveItems = res.data.content.data;
                } catch (err) {
                  console.log("expected data not showed up");
                }
                this.isDialog = false;
                this.paymentSchema = null;
                this.isPricePlanAnnual = null;
                this.priceAnnually = null;
                this.priceMonthly = null;
                this.citySubId = null;
              });
            }
            this.isLoading = false;
            this.isDialog = false;
          });
        this.reloadData();
      } else {
        if (!this.discountType) {
          this.errorDiscountType.message = "Please Select the Discount Type";
        }
        if (!this.discountValue) {
          this.errorDiscountValue.message = "Please Enter the Discount Value ";
        }
        if (this.discountType === "Percentage") {
          if (this.discountValue > 100) {
            this.errorDiscountValue.message = "Entered Percentage Exceeds 100 ";
          }
        }
        if (this.discountType === "Amount") {
          if (this.discountValue > this.priceAnnually) {
            this.errorDiscountValue.message =
              "Entered Amount Exceeds the Annual Price ";
          }
        }
      }
    },
    setAdvanceFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
        isAdvance: !val.isAdvance,
      };
      citySubscribersApicalls.setAdvance(payload).then((res) => {
        if (res.data.status == "success") {
          citySubscribersApicalls.getAllCitySub().then((res) => {
            this.activeItems = res.data.content.data;
          });
          citySubscribersApicalls.getAllCitySub().then((res) => {
            this.inactiveItems = res.data.content.data;
          });
        }
        this.isLoading = false;
      });
    },
    async approvalFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
        isAdvance: val.isAdvance,
        isApproved: !val.isApproved,
        googlePlaceId: val.googlePlaceId,
      };
      await citySubscribersApicalls.setApproval(payload).then(async (res) => {
        if (res.data.status == "success") {
          citySubscribersApicalls.getAllCitySub().then((res) => {
            this.activeItems = res.data.content.data;
            let payload2 = val.isApproved
              ? {
                  snackBarText: `This city's '${val.cityName}' approval has been revoked`,
                  snackBarColor: "secondary",
                }
              : {
                  snackBarText: `The city '${val.cityName}' has been approved`,
                  snackBarColor: "success",
                };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
          });
          await citySubscribersApicalls.getAllCitySub().then((res) => {
            this.inactiveItems = res.data.content.data;
            let payload2 = val.isApproved
              ? {
                  snackBarText: `This city's '${val.cityName}' approval has been revoked`,
                  snackBarColor: "secondary",
                }
              : {
                  snackBarText: `The city '${val.cityName}' has been approved`,
                  snackBarColor: "success",
                };
            this.$store.dispatch("notify/setSnackbarMessage", payload2);
          });
        } else {
          let payload2 = {
            snackBarText: `This city '${val.cityName}' is owned by someone else`,
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }
        this.getInactiveSubscribers();
        this.getActiveSubscribers();
        this.isLoading = false;
      });
    },
    async deleteFn(val) {
      this.isLoading = true;
      let payload = {
        _id: val._id,
      };
      await citySubscribersApicalls.setDelete(payload).then((res) => {
        if (res.data.status == "success") {
          let payload2 = {
            snackBarText: `The city subscriber ${val.name}'s account has been deleted successfully`,
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          citySubscribersApicalls.getAllCitySub().then((res) => {
            this.activeItems = res.data.content.data;
          });
          citySubscribersApicalls.getAllCitySub().then((res) => {
            this.inactiveItems = res.data.content.data;
          });
        }
        this.reloadData();
        this.isLoading = false;
      });
    },
    payloadGen(city) {
      return {
        name: city.name,
        type: "City Subscriber",
        _id: city._id,
      };
    },
    RouteFn(id, email) {
      this.$store.dispatch("setUserEmail", email);
      localStorage.setItem("setUserEmail", email);
      this.$router.push(`/city-subscribers-view/${id}`);
      // console.log(id);
    },
    getCityApprovalState(isPaymentApproved) {
      if (isPaymentApproved == 0) {
        return "Initial";
      } else if (isPaymentApproved == 1) {
        return "Requested";
      } else if (isPaymentApproved == 2) {
        return "Approved";
      } else if (isPaymentApproved == 3) {
        return "Rejected";
      } else if (isPaymentApproved == 4) {
        return "Paid";
      } else {
        return "Pending";
      }
    },
    getCityApprovalColor(isPaymentApproved) {
      if (isPaymentApproved == 0) {
        return "dark";
      } else if (isPaymentApproved == 1) {
        return "warning";
      } else if (isPaymentApproved == 2) {
        return "success";
      } else if (isPaymentApproved == 3) {
        return "error";
      } else if (isPaymentApproved == 4) {
        return "success";
      } else {
        return "info";
      }
    },
    searchFn() {
      if (
        (this.activeSearch && this.activeSearch.length > 0) ||
        (this.inactiveSearch && this.inactiveSearch.length)
      ) {
        this.activePagination.page = 1;
        this.inactivePagination.page = 1;

        this.getActiveSubscribers();
        this.getInactiveSubscribers();
      } else {
        this.clearFilterFn();
      }
    },
    clearFilterFn() {
      this.activeSearch = null;
      this.inactiveSearch = null;

      this.activePagination.page = 1;
      this.inactivePagination.page = 1;

      this.getActiveSubscribers();
      this.getInactiveSubscribers();
    },
  },
};
</script>
<style scoped>
.upgrade-btn {
  min-width: 140px !important;
}

.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>
