<template>
  <v-dialog v-model="isDialog" scrollable max-width="800px">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="primary" light>
        <font-awesome-icon :icon="['fas', 'money-check-alt']" color="danger" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-2 px-sm-6">
        <v-row class="pb-3">
          <v-col
            cols="8"
            lg="10"
            class="d-flex flex-column justify-center align-start py-0"
          >
            <h2 class="headline text-left">Manual Direct Payment</h2>
          </v-col>
          <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="grey lighten-1" @click="closeDialog">
              <v-icon class="dialog-close-icon">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container class="px-0">
          <v-row>
            <!-- type of payment -->
            <v-col cols="12" class="mb-1">
              <h6 class="d-block text-left title black--text">Payment Plan:</h6>
              <v-radio-group v-model="cityPlan" hide-details row>
                <v-radio value="basic">
                  <template v-slot:label>
                    <!-- display: block -->
                    <span class="d-block text-left subtitle-1 black--text">
                      Basic
                    </span>
                  </template>
                </v-radio>
                <v-radio value="advanced">
                  <template v-slot:label>
                    <!-- display: block -->
                    <span class="d-block text-left subtitle-1 black--text">
                      Advanced
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <!-- if the plan chosen is advanced -->
            <template v-if="cityPlan">
              <!-- advanced metrics for price calculation -->
              <template v-if="isAdvancedCity">
                <v-col cols="12" class="mb-3">
                  <v-row class="d-flex flex-row flex-wrap" no-gutters>
                    <v-col cols="12" md="6" class="pr-0 pr-md-3 py-0">
                      <v-select
                        label="Distance to Airport"
                        v-model="airport"
                        :items="airportCalList"
                        return-object
                        item-text="str"
                        outlined
                        :error-messages="errorAirport"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pl-0 pl-md-3 py-0">
                      <v-select
                        label="Population"
                        v-model="population"
                        :items="populationCalList"
                        return-object
                        item-text="str"
                        outlined
                        :error-messages="errorPopulation"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pr-0 pr-md-3 py-0">
                      <v-select
                        label="Maximum Number of Delegates"
                        v-model="delegates"
                        :items="delegatesCalList"
                        return-object
                        item-text="str"
                        outlined
                        :error-messages="errorDelegates"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pl-0 pl-md-3 py-0">
                      <v-select
                        label="Number of Association Meetings (Previous Year)"
                        v-model="meetings"
                        :items="meetingsCalList"
                        return-object
                        item-text="str"
                        outlined
                        :error-messages="errorMeetings"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pr-0 pr-md-3 py-0">
                      <v-select
                        label="Team Members"
                        v-model="team"
                        :items="teamCalList"
                        return-object
                        item-text="str"
                        outlined
                        :error-messages="errorTeam"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="d-flex flex-row flex-wrap" no-gutters>
                    <!-- capital city checkbox -->
                    <v-col cols="12" md="6" class="pr-0 pr-md-3 py-0">
                      <v-row
                        class="d-flex flex-row align-center justify-start mx-0"
                      >
                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column
                            align-end
                            justify-center
                            py-1
                            px-0
                          "
                        >
                          <v-switch
                            class="selection-checkboxes w-100"
                            v-model="isCapital"
                          >
                            <template v-slot:label>
                              <v-row
                                class="
                                  d-flex
                                  flex-row
                                  align-center
                                  justify-start
                                  mx-0
                                "
                              >
                                <v-col
                                  cols="2"
                                  sm="1"
                                  class="
                                    d-flex
                                    flex-column
                                    align-start
                                    justify-center
                                    px-0
                                    py-1
                                  "
                                >
                                  <font-awesome-icon
                                    class="feature-icon pa-1"
                                    :icon="['fas', 'building']"
                                    color="white"
                                    size="xs"
                                  />
                                </v-col>
                                <v-col
                                  cols="10"
                                  sm="11"
                                  class="
                                    d-flex
                                    flex-column
                                    align-start
                                    justify-center
                                    pl-2
                                    py-1
                                  "
                                >
                                  <h5 class="subtitle-2 text-left black--text">
                                    Capital
                                  </h5>
                                </v-col>
                              </v-row>
                            </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- convention bureau checkbox -->
                    <v-col cols="12" md="6" class="pl-0 pl-md-3 py-0">
                      <v-row
                        class="d-flex flex-row align-center justify-start mx-0"
                      >
                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column
                            align-end
                            justify-center
                            py-1
                            px-0
                          "
                        >
                          <v-switch
                            class="selection-checkboxes w-100"
                            v-model="isConvention"
                          >
                            <template v-slot:label>
                              <v-row
                                class="
                                  d-flex
                                  flex-row
                                  align-center
                                  justify-start
                                  mx-0
                                "
                              >
                                <v-col
                                  cols="2"
                                  sm="1"
                                  class="
                                    d-flex
                                    flex-column
                                    align-start
                                    justify-center
                                    px-0
                                    py-1
                                  "
                                >
                                  <font-awesome-icon
                                    class="feature-icon pa-1"
                                    :icon="['fas', 'info-circle']"
                                    color="white"
                                    size="xs"
                                  />
                                </v-col>
                                <v-col
                                  cols="10"
                                  sm="11"
                                  class="
                                    d-flex
                                    flex-column
                                    align-start
                                    justify-center
                                    pl-2
                                    py-1
                                  "
                                >
                                  <h5 class="subtitle-2 text-left black--text">
                                    Convention Bureau serves one destination
                                  </h5>
                                </v-col>
                              </v-row>
                            </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- internation train stop bureau checkbox -->
                    <v-col cols="12" md="6" class="pr-0 pr-md-3 py-0">
                      <v-row
                        class="d-flex flex-row align-center justify-start mx-0"
                      >
                        <v-col
                          cols="12"
                          class="
                            d-flex
                            flex-column
                            align-end
                            justify-center
                            py-1
                            px-0
                          "
                        >
                          <v-switch
                            class="selection-checkboxes w-100"
                            v-model="isTrain"
                          >
                            <template v-slot:label>
                              <v-row
                                class="
                                  d-flex
                                  flex-row
                                  align-center
                                  justify-start
                                  mx-0
                                "
                              >
                                <v-col
                                  cols="2"
                                  sm="1"
                                  class="
                                    d-flex
                                    flex-column
                                    align-start
                                    justify-center
                                    px-0
                                    py-1
                                  "
                                >
                                  <font-awesome-icon
                                    class="feature-icon pa-1"
                                    :icon="['fas', 'train']"
                                    color="white"
                                    size="xs"
                                  />
                                </v-col>
                                <v-col
                                  cols="10"
                                  sm="11"
                                  class="
                                    d-flex
                                    flex-column
                                    align-start
                                    justify-center
                                    pl-2
                                    py-1
                                  "
                                >
                                  <h5 class="subtitle-2 text-left black--text">
                                    International Train Stop in the City
                                  </h5>
                                </v-col>
                              </v-row>
                            </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mx-0">
                    <v-col cols="6" class="py-0 px-0">
                      <h3
                        class="title text-left secondary--text black--text pb-1"
                      >
                        Calculated Annual Price
                      </h3>
                    </v-col>
                    <!-- the annual price would come here -->
                    <v-col cols="6" class="py-0 px-0">
                      <h3
                        class="
                          title
                          text-right
                          secondary--text
                          black--text
                          font-weight-bold
                          pb-1
                        "
                      >
                        €{{ annualPrice }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-col>
              </template>
              <!-- annual price -->
              <v-col cols="12" class="mb-1">
                <v-text-field
                  label="Annual Price"
                  v-model="price"
                  prefix="€"
                  outlined
                  required
                  :error-messages="errorPrice"
                ></v-text-field>
              </v-col>
              <!-- VAT Calculation -->
              <v-col cols="12" class="py-0">
                <h6 class="d-block text-left title black--text mb-3">
                  VAT Calculation:
                </h6>
                <v-row class="d-flex flex-row flex-wrap" no-gutters>
                  <v-col cols="12" md="6" class="pr-0 pr-md-3 py-0">
                    <CountryAutocomplete
                      v-model="country"
                      :errorMessages="errorCountry"
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="pl-0 pl-md-3 py-0">
                    <v-text-field
                      ref="vat"
                      v-model="vat"
                      :error-messages="errorVat"
                      :success-messages="successVat"
                      @blur="validateVAT"
                      @keydown.enter="validateVAT"
                      :loading="vatLoading"
                      label="VAT Number"
                      outlined
                      required
                      hint="Enter your VAT Number with the country code prefixed"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!-- final prices -->
              <v-col cols="12" class="py-0">
                <v-row
                  class="mx-0 w-100 flex-grow-0"
                  v-if="userType == 'citySubscriber'"
                >
                  <v-col
                    cols="9"
                    class="
                      d-flex
                      flex-column
                      align-start
                      justify-center
                      px-0
                      py-2
                    "
                  >
                    <span
                      v-if="user.discountType == 'percentage'"
                      class="title text-left platinum--text text--darken-3"
                      >Discount ({{ user.discountValue }}%):</span
                    >
                    <span
                      v-else
                      class="title text-left platinum--text text--darken-3"
                      >Discount (€{{ user.discountValue }}):</span
                    >
                  </v-col>
                  <v-col
                    cols="3"
                    class="
                      d-flex
                      flex-column
                      align-end
                      justify-center
                      px-0
                      py-2
                    "
                  >
                    <!-- put the user's name here  -->
                    <!-- if striked out, add the class striked-price -->
                    <span
                      class="
                        title
                        text-left
                        secondary--text
                        vat-price
                        striked-price
                      "
                      >€{{ getDiscountedValue }}</span
                    >
                  </v-col>
                </v-row>
                <v-row class="mx-0 w-100 flex-grow-0">
                  <v-col
                    cols="9"
                    class="
                      d-flex
                      flex-column
                      align-start
                      justify-center
                      px-0
                      py-2
                    "
                  >
                    <span class="title text-left platinum--text text--darken-3"
                      >{{ computedVatState }} ({{ vatPercentage }}%):</span
                    >
                  </v-col>
                  <v-col
                    cols="3"
                    class="
                      d-flex
                      flex-column
                      align-end
                      justify-center
                      px-0
                      py-2
                    "
                  >
                    <!-- put the user's name here  -->
                    <!-- if striked out, add the class striked-price -->
                    <span
                      class="title text-left secondary--text vat-price"
                      :class="isComputedVat ? null : 'striked-price'"
                      >€{{ vatPrice }}</span
                    >
                  </v-col>
                </v-row>
                <v-row class="mx-0 w-100 flex-grow-0">
                  <v-col
                    cols="6"
                    class="
                      d-flex
                      flex-column
                      align-start
                      justify-center
                      px-0
                      py-2
                    "
                  >
                    <span class="title text-left platinum--text text--darken-3"
                      >Total:</span
                    >
                  </v-col>
                  <v-col
                    cols="6"
                    class="
                      d-flex
                      flex-column
                      align-end
                      justify-center
                      px-0
                      py-2
                    "
                  >
                    <!-- put the user's name here  -->
                    <span class="title text-left secondary--text"
                      >€{{ totalPrice }}</span
                    >
                  </v-col>
                </v-row>
                <v-row
                  class="d-flex flex-row align-center justify-start mx-0 py-0"
                >
                  <v-col
                    cols="12"
                    class="
                      d-flex
                      flex-column
                      align-start
                      justify-center
                      px-0
                      py-1
                      mb-6
                    "
                  >
                    <span
                      class="text-left caption platinum--text text--darken-3"
                      v-if="!isNL"
                    >
                      <i v-if="country"
                        >* The VAT for {{ country.name }} is
                        {{ computedVatFootnoteState }}</i
                      >
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row
          class="
            d-flex
            flex-column-reverse flex-sm-row
            align-center
            justify-space-between
            mx-0
          "
        >
          <v-col
            cols="12"
            sm="3"
            class="d-flex flex-column align-center align-sm-start px-0"
          >
            <v-btn
              class="px-8"
              color="secondary"
              @click="closeDialog"
              tile
              outlined
              depressed
              bottom
              >Cancel</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            sm="3"
            class="d-flex flex-column align-center align-sm-end px-0"
          >
            <v-btn
              class="px-8"
              :loading="isApiLoader"
              @click="paymentApprovalSubmitFn"
              color="secondary"
              tile
              depressed
              bottom
              right
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CountryAutocomplete from "@/dashboard/Common/CountryAutocomplete";
import paymentService from "@/services/payment.service";
export default {
  name: "MakePaymentModal",
  components: {
    CountryAutocomplete,
  },
  props: {
    item: Object,
    approvalFn: Function
  },
  data() {
    return {
      user: null,
      userType: null,
      country: null,
      errorCountry: null,
      isApiLoader: false,
      cityPlan: null,
      vatPercentage: 0,
      isVatNeeded: false,
      isEu: false,
      isNL: false,
      vat: null,
      errorVat: [],
      successVat: [],
      vatLoading: false,
      vatData: null,
      vatNumData: null,
      price: null,
      errorPrice: null,
      isDialog: false,
      isCapital: false,
      isCapitalIndeterminate: true,
      isConvention: false,
      isConventionIndeterminate: true,
      isTrain: false,
      isTrainIndeterminate: true,
      airport: null,
      population: null,
      delegates: null,
      meetings: null,
      team: null,
      errorAirport: null,
      errorPopulation: null,
      errorDelegates: null,
      errorMeetings: null,
      errorTeam: null,
      errorIsCapital: null,
      errorIsConvention: null,
      errorIsTrain: null,
      staticErrorMessages: {
        airport: { isEmpty: "Please select the distance range" },
        population: { isEmpty: "Please select population range" },
        delegates: { isEmpty: "Please select delegates range" },
        meetings: { isEmpty: "Please select meetings count range" },
        team: { isEmpty: "Please select the team members range" },
        isCapital: { isEmpty: "Please select if your city is the Capital" },
        isConvention: {
          isEmpty:
            "Please select if your city's Convention Bureau serves one destination",
        },
        isTrain: {
          isEmpty: "Please select if your city has an International Train Stop",
        },
      },
      airportCalList: [
        { str: "≤ 20 km", val: 1500 },
        { str: "21-50 km", val: 1000 },
        { str: "51-100 km", val: 500 },
        { str: "≥ 101 km", val: 0 },
      ],
      populationCalList: [
        { str: "≤ 300.000", val: 0 },
        { str: "300.001 - 600.000", val: 500 },
        { str: "600.001 - 1.000.000", val: 1000 },
        { str: "≥ 1.000.001", val: 1500 },
      ],
      delegatesCalList: [
        { str: "≤ 1500", val: 500 },
        { str: "1501-3500", val: 1000 },
        { str: "3501-6000", val: 1500 },
        { str: "≥6001", val: 2000 },
      ],
      meetingsCalList: [
        { str: "≤5", val: 500 },
        { str: "6-20", val: 1000 },
        { str: "21-40", val: 1500 },
        { str: "≥41", val: 2000 },
      ],
      teamCalList: [
        { str: "≤5", val: 500 },
        { str: "6-12", val: 1000 },
        { str: "13-19", val: 1500 },
        { str: "≥ 20", val: 2000 },
      ],
    };
  },
  mounted() {
    this.user = this.item;
    // console.log("user", this.item);
    this.userType = this.item.userType;
  },
  computed: {
    computedPlanType() {
      if (this.userType == "organiser") {
        return "1 Credit";
      } else if (this.userType == "venue") {
        return "Venue Partner Plan";
      } else {
        return this.isAdvancedCity ? "City Advanced Plan" : "City Basic Plan";
      }
    },
    annualPrice() {
      var val = 0;
      if (this.isCapital) {
        val = val + 500;
      }
      if (this.isConvention) {
        val = val + 500;
      }
      if (this.isTrain) {
        val = val + 500;
      }
      if (this.airport) {
        val = val + this.airport.val;
      }
      if (this.population) {
        val = val + this.population.val;
      }
      if (this.delegates) {
        val = val + this.delegates.val;
      }
      if (this.meetings) {
        val = val + this.meetings.val;
      }
      if (this.team) {
        val = val + this.team.val;
      }
      return val;
    },
    monthlyPrice() {
      return this.annualPrice / 12;
    },
    isAdvancedCity() {
      return this.cityPlan == "advanced";
    },
    typePrice() {
      if (this.userType == "citySubscriber") {
        return this.isAdvancedCity && this.user.priceAnnually
          ? this.user.annualPrice
          : 500;
      } else if (this.userType == "organiser") {
        return this.user.price;
      } else {
        return 500;
      }
    },
    customPrice() {
      // override price if a custom price is added
      if (this.price == null || this.price == 0) {
        return this.typePrice;
      } else {
        return this.price;
      }
    },
    computedVatState() {
      if (this.isEu) {
        if (this.isNL) {
          return "VAT";
        } else {
          return "VAT*";
        }
      } else {
        return "VAT*";
      }
    },
    computedVatFootnoteState() {
      if (this.isEu) {
        if (this.isNL) {
          return "";
        } else {
          return "Out of Scope";
        }
      } else {
        return "Prolonged";
      }
    },
    isComputedVat() {
      if (this.isEu) {
        if (this.isNL) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    vatPrice() {
      // override price if a custom price is added
      let price;
      if (this.customPrice == null || this.customPrice == 0) {
        price = this.typePrice;
      } else {
        price = this.customPrice;
      }
      return (
        (parseFloat(price - this.getDiscountedValue) * this.vatPercentage) / 100
      );
    },
    totalPrice() {
      // override price if a custom price is added
      let price;
      if (this.customPrice == null || this.customPrice == 0) {
        price = this.typePrice;
      } else {
        price = this.customPrice;
      }

      if (this.isEu) {
        if (this.isNL) {
          let vatPrice = this.vatPrice;
          return parseFloat(price - this.getDiscountedValue) + vatPrice;
        } else {
          return parseFloat(price - this.getDiscountedValue);
        }
      } else {
        return parseFloat(price - this.getDiscountedValue);
      }
    },
    getDiscountedValue() {
      // override price if a custom price is added
      let price;
      if (this.customPrice == null || this.customPrice == 0) {
        price = this.typePrice;
      } else {
        price = this.customPrice;
      }
      if (this.type == "citySubscriber" && this.user.isDiscount) {
        if (this.user.discountType == "amount") {
          return this.user.discountValue;
        } else {
          return (this.user.discountValue / 100) * price;
        }
      } else {
        return 0;
      }
    },
  },
  watch: {
    country: {
      immediate: true,
      handler(val) {
        if (val) {
          this.errorCountry = null;
          if (val.abbreviation == "NL") {
            this.isNL = true;
          } else {
            this.isNL = false;
          }
          paymentService.vatRate(val.abbreviation).then((res) => {
            if (res.data.success) {
              this.vatPercentage = res.data.tax_rate.rate;
              this.isEu = res.data.eu;
              this.vatData = res.data;
              // tax_rate.rate
            }
          });
          this.vatPercentage = 18;
        }
      },
    },
    vat() {
      this.errorVat = null;
    },
    airport() {
      this.errorAirport = null;
    },
    population() {
      this.errorPopulation = null;
    },
    delegates() {
      this.errorDelegates = null;
    },
    meetings() {
      this.errorMeetings = null;
    },
    team() {
      this.errorTeam = null;
    },
    isCapital() {
      this.errorIsCapital = null;
      // console.log("user", this.user);
    },
    isConvention() {
      this.errorIsConvention = null;
    },
    isTrain() {
      this.errorIsTrain = null;
    },
  },
  methods: {
    closeDialog() {
      this.isDialog = false;
    },
    async paymentApprovalSubmitFn() {
      /**
       *if EU ?  Validate Vat Number : No Vat Number
       */
      !this.country ? (this.errorCountry = "Please select your Country") : null;
      if (!this.country) {
        return;
      }
      if (this.isEu) {
        if (this.vat) {
          let { data } = await paymentService.vatNum(this.vat);
          if (data.success) {
            if (data.valid) {
              this.vatNumData = data;
              await this.manualDirectPay();
            } else {
              this.errorVat = "Invalid VAT Number";
            }
          } else {
            this.errorVat = "Invalid VAT Number";
          }
        } else {
          this.errorVat = "Invalid VAT Number";
        }
      } else {
        await this.manualDirectPay();
        this.closeDialog();
      }
      location.reload();
    },
    payloadGenaratorFn() {
      // console.log("gen item", this.item);
      let payload = {
        type: this.userType,
        isDiscount:
          this.userType == "citySubscriber"
            ? this.user.isDiscount
              ? this.user.isDiscount
              : false
            : false,
        discountType:
          this.userType == "citySubscriber" ? this.user.discountType : "nan",
        discountValue:
          this.userType == "citySubscriber" ? this.user.discountValue : 0,
        isEU: this.isEu,
        isNL: this.isNL,
        email: this.user.email,
        name: this.user.name,
        userId: this.user._id,
        isRenew: true,
        price: {
          vat: this.vatPrice,
          totalPrice: this.totalPrice,
          price: this.customPrice,
        },
        vatData: {
          ...this.vatData,
          str: this.computedVatState,
          plan: this.computedPlanType,
          isVat: this.isComputedVat,
          country: this.country,
          VatFootnoteState: this.computedVatFootnoteState,
        },
        vatNumData: this.vatNumData,
        isPricePlanAnnual: true,
        priceAnnually: this.annualPrice,
        priceMonthly: this.monthlyPrice,
        paymentSchema: {
          isCapital: this.isCapital,
          isConvention: this.isConvention,
          isTrain: this.isTrain,
          airport: this.airport,
          population: this.population,
          delegates: this.delegates,
          meetings: this.meetings,
          team: this.team,
        },
      };
      if (this.userType == "citySubscriber") {
        if (this.isAdvancedCity) {
          payload.details = {
            isAdvanced: true,
            price: this.annualPrice,
            str: "City Subscriber Advanced Annual Payment",
          };
          payload.profile = {
            name: this.user.cityName,
            address: this.user.formattedAddress,
          };
        } else {
          payload.details = {
            isAdvanced: false,
            price: 500,
            str: "City Subscriber Basic Annual Payment",
          };
          payload.profile = {
            name: this.user.cityName,
            address: this.user.formattedAddress,
          };
        }
      } else if (this.userType == "organiser") {
        payload.details = {
          price: this.annualPrice,
          credit: 1,
          str: "Organiser Payment",
        };
        payload.profile = {
          name: this.user.organisationName,
          address: this.user.address,
        };
      } else {
        payload.details = {
          str: "Venue Annual Payment",
        };
        payload.profile = {
          name: this.annualPrice.venueName,
          address: this.annualPrice.address,
        };
      }
      return payload;
    },
    async manualDirectPay() {
      this.isApiLoader = true;
      // console.log("payload1", this.user);
      let payload = await this.payloadGenaratorFn();
      // console.log("payload", payload);
      const { data } = await paymentService.directPaymentAdmin(payload);
      if (data.success) {
        this.isApiLoader = false;
        let payload = {
          snackBarText: "Direct Payment completed successfully",
          snackBarColor: "success",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.resetForm();
        this.closeDialog();
      } else {
        this.isApiLoader = false;
        let payload = {
          snackBarText: `Something went wrong`,
          snackBarColor: "error",
        };
        this.$store.dispatch("notify/setSnackbarMessage", payload);
        this.closeDialog();
      }
      location.reload();
    },
    async validateVAT() {
      /**
       *if EU ?  Validate Vat Number : No Vat Number
       */
      if (this.isEu) {
        if (this.vat) {
          this.vatLoading = true;
          let { data } = await paymentService.vatNum(this.vat);
          if (data.success) {
            this.vatLoading = false;
            if (data.valid) {
              this.successVat = "Valid VAT Number";
              return true;
            } else {
              this.errorVat = "Invalid VAT Number";
              return false;
            }
          } else {
            this.vatLoading = false;
            this.errorVat = "Invalid VAT Number";
            return false;
          }
        } else {
          this.vatLoading = false;
          this.errorVat = "Invalid VAT Number";
          return false;
        }
      } else {
        this.vatLoading = false;
        this.successVat = "";
        return true;
      }
    },

    resetForm() {
      this.price = null;
      // TODO - reset country field
      this.country = null;
      this.vat = null;
      this.successVat = null;
      this.airport = null;
      this.population = null;
      this.delegates = null;
      this.meetings = null;
      this.team = null;
      this.isCapital = false;
      this.isConvention = false;
      this.isTrain = false;
      this.$emit('reload');
    },
  },
};
</script>

<style scoped>
/* striked vat price  */
.vat-price.striked-price {
  text-decoration: line-through;
  text-decoration-thickness: 3px;
}
</style>
