
/**
 * Formate date to Date-Month-Year
 * @param {String} date 
 */
 export default function toYMD(dateStr) {
    const date = new Date(dateStr);
    const day = ('00' + date.getDate()).slice(-2);
    const month = ('00' + (date.getMonth() + 1)).slice(-2);
    return `${date.getFullYear()}-${month}-${day}`
}