<template>
  <v-dialog v-model="deleteDialog" :key="item._id" persistent max-width="400">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon color="red">
        <font-awesome-icon :icon="['fas', 'trash-alt']" color="red" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <h3 class="title text-start">
          Are you sure you want to delete '{{ item.name }}'?
        </h3>
      </v-card-title>
      <v-card-text>
        <p class="subtitle-2 text-left">
          You will be deleting all the data associated with this
          {{ item.type }} .
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="deleteDialog = false"
          >No, Cancel</v-btn
        >
        <!-- until test end -->
        <!-- <v-btn color="error" text @click="deleteFn(item)">Yes, Delete</v-btn> -->
        <v-btn color="error" text @click="deleteFn()">Yes, Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteConfirmation",
  props: {
    item: Object
    // itemType: String
  },
  data() {
    return {
      deleteDialog: false
    };
  },
  methods: {
    deleteFn() {
      this.deleteDialog = false;
      this.$emit("setDelete", this.item);
    }
  }
};
</script>
